import type { InferGetStaticPropsType } from 'next'
import { NextSeo } from 'next-seo'
import dynamic from 'next/dynamic'

import Header from './header'
import HeaderMd from './header-md'
import getStaticPaths from './getStaticPaths'
import getStaticProps from './getStaticProps'

const PageComponents = dynamic(
  import('@/features/page-components').then(({ PageComponents }) => PageComponents),
  { ssr: false },
)

const CampaignPage = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { slug, pageComponentResponse } = props

  const { name, description, imageUrls, introHtml } = pageComponentResponse.meta.page.attributes
  const { desktopBannerBgUrl, desktopBannerUrl, mobileBannerBgUrl, mobileBannerUrl, ogImageUrl } =
    imageUrls || {}

  const seoInfo = {
    titleTemplate: `${name} | %s`,
    description: description,
    openGraph: {
      title: name,
      description: description,
      images: [{ url: ogImageUrl || desktopBannerUrl, alt: `${slug}-cover` }],
      url: `${process.env.NEXT_PUBLIC_SELF_DOMAIN}/campaign/${slug}`,
    },
  }

  return (
    <>
      <NextSeo {...seoInfo} />
      <div className='relative w-full text-gray-800'>
        <Header
          alt={slug}
          mobileBannerUrl={mobileBannerUrl}
          mobileBannerBgUrl={mobileBannerBgUrl}
        />
        <HeaderMd
          alt={slug}
          desktopBannerUrl={desktopBannerUrl}
          desktopBannerBgUrl={desktopBannerBgUrl}
        />

        <>
          {introHtml ? (
            <div>
              <div
                className='mobileUp:py-4 mobileUp:px-0 [&_a]:border-primary-500 [&_a]:text-primary-500 mx-auto my-0 flex w-full max-w-[920px] flex-col items-center gap-2 p-4 text-center leading-[150%] [&_*]:m-0 [&_a]:border-b [&_a]:no-underline [&_h2]:text-xl [&_p]:text-base'
                dangerouslySetInnerHTML={{ __html: introHtml }}
              />
            </div>
          ) : null}

          <PageComponents pageComponentResponse={pageComponentResponse} />
        </>
      </div>
    </>
  )
}

export default CampaignPage

export { getStaticPaths, getStaticProps }
