import type { Context, SeverityLevel } from '@sentry/types'
import { notify } from '@libs-components/utils/error-notifier'

const getSentryLevel = (error: any): SeverityLevel => {
  const httpStatusCode = +error?.response?.status
  if (httpStatusCode >= 400 && httpStatusCode < 500) {
    return 'warning'
  }

  return 'error'
}

const sendUnexpectedErrorNotify = (err: any, additional: Context) => {
  const errorContext = err?.config?.errorContext
  const isValidErrorContext = typeof errorContext === 'object' && !Array.isArray(errorContext)

  notify({
    err,
    context: {
      key: 'apiCaller',
      level: getSentryLevel(err),
      ...(isValidErrorContext ? errorContext : {}),
      ...additional,
    },
  })
}

export { sendUnexpectedErrorNotify }
