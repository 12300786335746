import type { BuiltinEventNames, BuiltinEventPayloadMap } from './dadk'
import { isInClient, isProd } from '@/libs/env'
import { notify } from '@libs-components/utils/error-notifier'

type FacadeFuncParams<EventName extends BuiltinEventNames> = {
  payload: BuiltinEventPayloadMap[EventName]
  onSuccess?: () => void
  onError?: (err: unknown) => void
}

type FacadeFuncVoidPayloadParams<EventName extends BuiltinEventNames> = Omit<
  FacadeFuncParams<EventName>,
  'payload'
>

const sendDcardAdsEvent = <EventName extends BuiltinEventNames>(
  eventName: EventName,
  { payload, onSuccess, onError }: FacadeFuncParams<EventName>,
) => {
  try {
    if (!isInClient()) {
      const ERROR_MESSAGE_NOT_IN_CLIENT =
        '[Dcard Ads] cliet events emitter, does not send dcard ads event in server side.' as const
      if (!isProd) {
        console.log(
          `%c${ERROR_MESSAGE_NOT_IN_CLIENT}`,
          'color:white;font-size:0.75rem;background:#00324e;',
          eventName,
          payload,
        )
      }
      if (typeof onError === 'function') onError(new Error(ERROR_MESSAGE_NOT_IN_CLIENT))
      return
    }

    if (!window.dadk) {
      const ERROR_MESSAGE_NOT_IN_INITIALIZED =
        '[Dcard Ads] is not initialized, please check the Dcard Ads script was loaded or not.' as const
      if (!isProd) {
        console.log(
          `%c${ERROR_MESSAGE_NOT_IN_INITIALIZED}`,
          'color:white;font-size:0.75rem;background:#00324e;',
          eventName,
          payload,
        )
      }
      if (typeof onError === 'function') onError(new Error(ERROR_MESSAGE_NOT_IN_INITIALIZED))
      return
    }

    window.dadk('track', eventName, payload)
    if (typeof onSuccess === 'function') onSuccess()
  } catch (err) {
    notify({
      err,
      level: 'warning',
      context: { eventName, eventPayload: payload, message: 'Failed to send Dcard Ads event.' },
    })
    if (typeof onError === 'function') onError(err)
  }
}

/* ======================================================================================================= */
/* Provides a set of functions using facade pattern to send Dcard Ads events using the sendDcardAdsEvent.  */
/* These functions should be used instead of directly interacting with the window.dadk interface.          */
/* ======================================================================================================= */
export const sendDcardAdsPageViewEvent = (params: FacadeFuncParams<'PageView'>) => {
  sendDcardAdsEvent('PageView', params)
}

export const sendDcardAdsAddToCartEvent = (params: FacadeFuncParams<'AddToCart'>) => {
  sendDcardAdsEvent('AddToCart', params)
}

export const sendDcardAdsAddToWishlistEvent = (params: FacadeFuncParams<'AddToWishlist'>) => {
  sendDcardAdsEvent('AddToWishlist', params)
}

export const sendDcardAdsContactEvent = (params: FacadeFuncVoidPayloadParams<'Contact'> = {}) => {
  sendDcardAdsEvent('Contact', { ...params, payload: undefined })
}

export const sendDcardAdsCompleteRegistrationEvent = (
  params: FacadeFuncParams<'CompleteRegistration'>,
) => {
  sendDcardAdsEvent('CompleteRegistration', params)
}

export const sendDcardAdsCustomizeProductEvent = (
  params: FacadeFuncVoidPayloadParams<'CustomizeProduct'> = {},
) => {
  sendDcardAdsEvent('CustomizeProduct', { ...params, payload: undefined })
}

export const sendDcardAdsDonateEvent = (params: FacadeFuncVoidPayloadParams<'Donate'> = {}) => {
  sendDcardAdsEvent('Donate', { ...params, payload: undefined })
}

export const sendDcardAdsFindLocationEvent = (
  params: FacadeFuncVoidPayloadParams<'FindLocation'> = {},
) => {
  sendDcardAdsEvent('FindLocation', { ...params, payload: undefined })
}

export const sendDcardAdsInitiateCheckoutEvent = (params: FacadeFuncParams<'InitiateCheckout'>) => {
  sendDcardAdsEvent('InitiateCheckout', params)
}

export const sendDcardAdsLeadEvent = (params: FacadeFuncVoidPayloadParams<'Lead'> = {}) => {
  sendDcardAdsEvent('Lead', { ...params, payload: undefined })
}

export const sendDcardAdsPurchaseEvent = (params: FacadeFuncParams<'Purchase'>) => {
  sendDcardAdsEvent('Purchase', params)
}

export const sendDcardAdsScheduleEvent = (params: FacadeFuncVoidPayloadParams<'Schedule'> = {}) => {
  sendDcardAdsEvent('Schedule', { ...params, payload: undefined })
}

export const sendDcardAdsSearchEvent = (params: FacadeFuncParams<'Search'>) => {
  sendDcardAdsEvent('Search', params)
}

export const sendDcardAdsStartTrialEvent = (params: FacadeFuncParams<'StartTrial'>) => {
  sendDcardAdsEvent('StartTrial', params)
}

export const sendDcardAdsSubmitApplicationEvent = (
  params: FacadeFuncVoidPayloadParams<'SubmitApplication'> = {},
) => {
  sendDcardAdsEvent('SubmitApplication', { ...params, payload: undefined })
}

export const sendDcardAdsSubscribeEvent = (params: FacadeFuncParams<'Subscribe'>) => {
  sendDcardAdsEvent('Subscribe', params)
}

export const sendDcardAdsViewContentEvent = (params: FacadeFuncParams<'ViewContent'>) => {
  sendDcardAdsEvent('ViewContent', params)
}
