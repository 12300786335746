import { notify } from '@libs-components/utils/error-notifier'
import { credentialManager } from '@wordup/lib-shop/utils'

export const handleWuAppSignInSuccess = () => {
  const win = window as any
  try {
    if (win.webkit && win.webkit.messageHandlers && win.webkit.messageHandlers.notifySignIn) {
      win.webkit.messageHandlers.notifySignIn.postMessage(credentialManager.get())
    }
  } catch (err) {
    notify({ err, context: { key: 'wu app sign in error' } })
  }
}

export const postSignInSuccess = ({ beRes }: { beRes: any }) => {
  credentialManager.update(beRes)
  handleWuAppSignInSuccess()
}
