import { EXPECTED_ERROR_CODES as EXPECTED_BUYING_GROUP_ERROR_CODES } from '../../apis/shop/buying-group'
import { EXPECTED_ERROR_CODES as EXPECTED_CHECKOUT_ERROR_CODES } from '../../apis/shop/checkout'

export const isExpectedError = (error: any) => {
  const customError = {
    isExpected: true,
    responseErrorCode: error?.response?.data?.errors?.[0]?.code,
    responseErrorDetail: error?.response?.data?.errors?.[0]?.detail,
  }

  if (error?.response?.status === 404) return customError

  if (
    customError.responseErrorCode &&
    EXPECTED_ERROR_CODES.includes(customError.responseErrorCode)
  ) {
    return customError
  }

  if (
    customError.responseErrorDetail &&
    EXPECTED_ERROR_DETAILS.includes(customError.responseErrorDetail)
  ) {
    return customError
  }

  customError.isExpected = false

  return customError
}

const EXPECTED_ERROR_DETAILS = [
  "Couldn't find BuyingGroup",
  'You need to sign in or sign up before continuing.',
  '請註冊成為會員後再進行購買。',
]

const EXPECTED_ERROR_CODES = [
  ...EXPECTED_CHECKOUT_ERROR_CODES,
  ...EXPECTED_BUYING_GROUP_ERROR_CODES,
  'ECONNABORTED',
]
