import {
  NextSeo,
  BrandSeo,
  WebPageSeo,
  LogoSeo,
  OrganizationSeo,
  SocialProfileSeo,
  SEO_TITLE,
  SEO_DESCRIPTION,
} from '@libs-components/features'

const AppSeo = () => {
  return (
    <>
      <NextSeo
        title={SEO_TITLE}
        description={SEO_DESCRIPTION}
        openGraph={{
          url: process.env.NEXT_PUBLIC_SELF_DOMAIN,
          title: SEO_TITLE,
          siteName: SEO_TITLE,
          description: SEO_DESCRIPTION,
          images: [
            {
              url: 'https://s3-ap-northeast-1.amazonaws.com/wordup-production-about/og_img_new.jpg',
              alt: 'WORD UP Image',
            },
          ],
        }}
        facebook={{ appId: '407289366313573' }}
      />
      <LogoSeo />
      <SocialProfileSeo />
      <WebPageSeo />
      <BrandSeo />
      <OrganizationSeo />
    </>
  )
}

export default AppSeo
