export const LONGEST_COOKIE_EXPIRARY_TIME = 365 * 2 // * 2 years

export const USER_RELATED_KEY = {
  USER_ID: 'userId',
  CREDENTIAL: 'credential',
  USER_INFO: 'userInfo',
  MOBILE_HINT_STATUS: '_mobile_hint_status',
  SYNC_TUTORIAL_STATUS: '_sync_tutorial_status',
}

export type Stage = 'test' | 'development' | 'staging' | 'production'
