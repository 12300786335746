import type { ProductType } from '../../types'
import { isBefore } from 'date-fns'

export const getProgressPercentage = ({
  currentBuyerCount,
  goalBuyerCount,
}: {
  currentBuyerCount: number
  goalBuyerCount: number
}) => {
  if (!currentBuyerCount || !goalBuyerCount) return 0

  return Math.floor((currentBuyerCount / goalBuyerCount) * 100) || 0
}

export const isCountdownVisible = (countdown: ProductType.DataAttributes['countdown']) => {
  if (!countdown || !countdown?.endsAt) return false

  if (isBefore(new Date(countdown?.endsAt), new Date())) return false

  return true
}
